export const ROOT = 'https://api.pm.visibilitycam.com/';
//export const ROOT = 'http://localhost:8000/';
export const SERVER = ROOT + 'api/';

export const LOGIN = SERVER + 'auth/token';
export const UPDATE_PASSWORD = SERVER + 'auth/updatepassword';
export const USERS = SERVER + 'users';
export const CHAT = SERVER + 'chats';
export const DOCUMENT = SERVER + 'documents';
export const ROLE = SERVER + 'roles';
export const DISCIPLINARY_TEAMS = SERVER + 'disciplinary_teams';
export const DISCIPLINARY_BOARD = SERVER + 'disciplinary_boards';
export const FIND_USSER = SERVER + 'users';
export const USER_NOTE_CRITERIAS = SERVER + 'user_note_criteria';
export const Template = SERVER + 'templates';
export const assignment_type = SERVER + 'assignment_types';
export const PERMISSION = SERVER + 'permissions';
export const BLOG_CATEGORY = SERVER + 'blog_categories';
export const ASSIGNMENT = SERVER + 'assignments';
export const ASSIGNMENT_TYPE = SERVER + 'assignment_types';
export const NOTECRITERIA = SERVER + 'note_criterias';
export const PRO_SITUATION = SERVER + 'pro_situations';
export const CONTACT = SERVER + 'contacts';
export const VACATIONTYPE = SERVER + 'vacation_types';
export const VACATION_TYPE = SERVER + 'vacation_types';
export const LICENSE = SERVER + 'licenses';
export const LICENSE_TYPE = SERVER + 'license_types';
export const LICENSETYPE = SERVER + 'license_types';
export const PROFILES = SERVER + 'profiles';
export const DIVISION = SERVER + 'divisions';
export const CITY = SERVER + 'cities';
export const SANCTION = SERVER + 'sanctions';
export const CONTRACT = SERVER + 'contracts';

export const PROFILE = SERVER + 'profiles';
export const DiciplinaryTeam = SERVER + 'disciplinary_teams';
export const CAREER = SERVER + 'careers';
export const NOTECRITERIAS = SERVER + 'note_criterias';
export const ASSIGNMENTTYPE = SERVER + 'assignment_types';
export const dISCIPLINARYBOARD = SERVER + 'disciplinary_boards';
export const USER = SERVER + 'users';
export const BLOGCATEGORY = SERVER + 'blog_categories';
export const BLOGPOST = SERVER + 'blog_posts';
export const TRAINING = SERVER + 'trainings';
export const Setting = SERVER + 'settings';
export const DISCIPLINARYTEAM = SERVER + 'disciplinary_teams';
export const SUBMISSION = SERVER + 'submissions';
export const CITIES = SERVER + 'cities';
export const VACATION = SERVER + 'vacations';
export const BlogPost = SERVER + 'blog_posts';
export const User = SERVER + 'users';
export const blogComment = SERVER + 'blog_comments';
export const StatCareer = SERVER + 'statistics/career';
export const StatAssignment = SERVER + 'statistics/assignment_by_month';
export const SYNC_USER_ABILITIES = SERVER + 'sync_user_abilities/{user_id}';
export const RETROGRADE = SERVER + 'retrogrades';

