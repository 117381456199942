import { AuthService } from './../_services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  canCreate = false;
  canReadProSituation = false;
  canProfiles = false;
  canReadLicenseTypes = false;
  canReadDisciplinaryBoards = false;
  canReadRoles = false;
  canReadUsers = false;
  canReadVacations = false;
  canReadLicenses = false;
  canReadTemplates = false;
  canReadNoteCriterias = false;
  canReadAssignmentTypes = false;
  canReadAssignments = false;
  canReadVacationTypes = false;
  canReadSettings = false;
  canReadDivisions = false;
  canReadSubmissions = false;
  canReadStatistics = false;
  canReadContacts = false;
  canReadTrainings = false;
  canReadDisciplinaryTeams = false;
  canReadBlogCategories = false;
  canReadSanctions = false;
  canReadBlogPosts = false;
  canReadContracts = false;
  canReadCareers = false;

  constructor(private authService: AuthService,
  ) { }

  ngOnInit() {
    const permissionSuffix = 'pro-situations';
    this.canCreate = this.authService.hasPermission(`create-pro-situations`);
    this.canReadProSituation = this.authService.hasPermission(`read-pro-situations`);
    this.canProfiles = this.authService.hasPermission(`read-profiles`);
    this.canReadLicenseTypes = this.authService.hasPermission(`read-license-types`);
    this.canReadDisciplinaryBoards = this.authService.hasPermission(`read-disciplinary-boards`);
    this.canReadRoles = this.authService.hasPermission(`read-roles`);
    this.canReadUsers = this.authService.hasPermission(`read-users`);
    this.canReadVacations = this.authService.hasPermission(`read-vacations`);
    this.canReadLicenses = this.authService.hasPermission(`read-licenses`);
    this.canReadTemplates = this.authService.hasPermission(`read-templates`);
    this.canReadNoteCriterias = this.authService.hasPermission(`read-note-criterias`);
    this.canReadAssignmentTypes = this.authService.hasPermission(`read-assignment-types`);
    this.canReadAssignments = this.authService.hasPermission(`read-assignments`);
    this.canReadVacationTypes = this.authService.hasPermission(`read-vacation-types`);
    this.canReadSettings = this.authService.hasPermission(`read-settings`);
    this.canReadDivisions = this.authService.hasPermission(`read-divisions`);
    this.canReadSubmissions = this.authService.hasPermission(`read-submissions`);
    this.canReadStatistics = this.authService.hasPermission(`read-statistics`);
    this.canReadContacts = this.authService.hasPermission(`read-contacts`);
    this.canReadTrainings = this.authService.hasPermission(`read-trainings`);
    this.canReadDisciplinaryTeams = this.authService.hasPermission(`read-disciplinary-teams`);
    this.canReadBlogCategories = this.authService.hasPermission(`read-blog-categories`);
    this.canReadSanctions = this.authService.hasPermission(`read-sanctions`);
    this.canReadBlogPosts = this.authService.hasPermission(`read-blog-posts`);
    this.canReadContracts = this.authService.hasPermission(`read-contracts`);
    this.canReadCareers = this.authService.hasPermission(`read-careers`);

  }

}
