import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Routes from '../Routes'; 

@Injectable({
  providedIn: 'root'
})
export class RetrogradeService {

  constructor(private http:HttpClient) { }
  add(formData: FormData): Promise<any> {
    return this.http.post<any>(Routes.RETROGRADE, formData).toPromise();
}

find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.RETROGRADE}/${id}`).toPromise();
}
users():  Promise<any> {
    return this.http.get<any>(Routes.USERS).toPromise();
}

pro_situations():  Promise<any> {
    return this.http.get<any>(Routes.PRO_SITUATION).toPromise();
}
divisions():  Promise<any> {
    return this.http.get<any>(Routes.DIVISION).toPromise();
}
Retrogrades():  Promise<any> {
    return this.http.get<any>(Routes.RETROGRADE).toPromise();
}

}
