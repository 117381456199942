import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-find-settings',
  templateUrl: './find-settings.component.html',
  styleUrls: ['./find-settings.component.scss']
})
export class FindSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
