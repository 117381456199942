import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-find-pro-situation',
  templateUrl: './find-pro-situation.component.html',
  styleUrls: ['./find-pro-situation.component.scss']
})
export class FindProSituationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
